


























































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {
  CaoCompliance,
  CaoComplianceListAction,
  CheckInlenerCaoAction,
  InlenerCao,
  InlenerCaoListAction,
  InlenerDetails,
  InlenerDetailsAction,
  InlenerType,
  ToggleCaoComplianceAction,
  ToggleFavoriteInlener,
  ToggleInlenerCaoAction,
} from '@/store/inleners/types';
import VButton from '@/components/VButton.vue';
import createList from '@/utilities/lists';
import debounce from '@/utilities/debounce';
import UserInfo from '@/interfaces/userInfo';
import {toReadableDateFormat} from '@/utilities/filters';

@Component({
  components: {VButton},
  filters: {toReadableDateFormat},
})
export default class InlenerDetail extends Vue {

  @Action('inlenerDetails', {namespace: 'inleners'}) private getInlenerDetails!: InlenerDetailsAction;
  @Action('inlenerInvite', {namespace: 'inleners'}) private getInlenerInvite!: InlenerDetailsAction;
  @Action('inlenerCaos', {namespace: 'inleners'}) private getInlenerCaos!: InlenerCaoListAction;
  @Action('caoCompliances', {namespace: 'inleners'}) private getCaoCompliances!: CaoComplianceListAction;
  @Action('toggleInlenerCaos', {namespace: 'inleners'}) private toggleInlenerCaos!: ToggleInlenerCaoAction;
  @Action('toggleCaoCompliances', {namespace: 'inleners'}) private toggleCaoCompliances!: ToggleCaoComplianceAction;
  @Action('checkInlenerCao', {namespace: 'inleners'}) private checkInlenerCao!: CheckInlenerCaoAction;
  @Action('toggleFavoriteInlener', {namespace: 'inleners'}) private toggleFavoriteInlener!: ToggleFavoriteInlener;
  @Getter('userInfo', {namespace: 'account'}) private userInfo!: UserInfo;


  private fetchingInlenerDetail = false;

  private inlenerDetails: InlenerDetails | null = null;

  private inlenerCaos = createList<InlenerCao>({
    contains: '',
    page: 1,
    only_user: false,
    inlener_subscription_id: null,
  });
  private caoCompliances = createList<CaoCompliance>({
    contains: '',
    page: 1,
    only_user: false,
    inlener_subscription_id: null,
  });

  private modalInlenerCao: InlenerCao | null = null;
  private modalComplianceCao: CaoCompliance | null = null;
  private requestingCheck = false;

  private debouncedGetInlenerCaos = debounce(this.fetchInlenersCaos);
  private debouncedGetCaoCompliances = debounce(this.fetchCaoCompliances);

  private created() {
    this.fetchingInlenerDetail = true;

    if (this.$route.params.type === InlenerType.SUBSCRIPTION) {
      this.getInlenerDetails(this.$route.params.inlener_id).then((response) => {
        this.inlenerDetails = response;
        if (!this.inlenerDetails?.accounts) {
          return;
        }
        this.inlenerCaos.filters.inlener_subscription_id = this.$route.params.inlener_id;
        this.fetchInlenersCaos();
        this.caoCompliances.filters.inlener_subscription_id = this.$route.params.inlener_id;
        this.fetchCaoCompliances();
      }).finally(() => {
        this.fetchingInlenerDetail = false;
      });
    } else {
      this.getInlenerInvite(this.$route.params.inlener_id).then((response) => {
        this.inlenerDetails = response;
      }).finally(() => {
        this.fetchingInlenerDetail = false;
      });
    }
  }

  private fetchInlenersCaos() {
    this.inlenerCaos.fetching = true;
    this.getInlenerCaos(this.inlenerCaos.filters).then((result) => {
      this.inlenerCaos = {
        ...this.inlenerCaos,
        ...result,
      };
    }).catch(() => {
      this.inlenerCaos.models = [];
    }).finally(() => this.inlenerCaos.fetching = false);
  }

  private fetchCaoCompliances() {
    this.caoCompliances.fetching = true;
    this.getCaoCompliances(this.caoCompliances.filters).then((result) => {
      this.caoCompliances = {
        ...this.caoCompliances,
        ...result,
      };
    }).catch(() => {
      this.caoCompliances.models = [];
    }).finally(() => this.caoCompliances.fetching = false);
  }

  @Watch('inlenerCaos.filters.contains')
  @Watch('inlenerCaos.filters.page')
  @Watch('inlenerCaos.filters.only_user')
  private inlenersCaosFilterWatcher() {
    this.debouncedGetInlenerCaos();
  }

  @Watch('caoCompliances.filters.contains')
  @Watch('caoCompliances.filters.page')
  @Watch('caoCompliances.filters.only_user')
  private caoCompliancesFilterWatcher() {
    this.debouncedGetCaoCompliances();
  }

  private showCaoCheckModal(inlenerCao: InlenerCao) {
    this.modalInlenerCao = inlenerCao;
    (this.$refs['check-cao-modal-1'] as any).show();
  }

  private requestCheck() {
    this.requestingCheck = true;
    this.checkInlenerCao({inlener_cao_id: this.modalInlenerCao!.id.toString()})
      .finally(() => {
        this.inlenerCaos.filters.page = 1;
        this.inlenerCaos.fetching = true;
        this.getInlenerCaos(this.inlenerCaos.filters).then((result) => {
          this.inlenerCaos = {
            ...this.inlenerCaos,
            ...result,
          };
        }).finally(() => {
          this.inlenerCaos.fetching = false;
          (this.$refs['check-cao-modal-1'] as any).hide();
          this.requestingCheck = false;
        });
      });
  }

  private toggleInlenerCao(inlenerCao: InlenerCao) {
    this.toggleInlenerCaos({
      inlener_cao_id: inlenerCao.id,
      api: inlenerCao.api,
      tool: inlenerCao.tool,
    });
  }

  private toggleCaoCompliance(caoCompliance: CaoCompliance) {
    this.toggleCaoCompliances({
      cao_compliance_id: caoCompliance.id,
      api: caoCompliance.api,
    });
  }

  private showCaoNotInAgreementModal(inlenerCao: InlenerCao) {
    this.modalInlenerCao = inlenerCao;
    (this.$refs['cao-not-in-agreement-modal-1'] as any).show();
  }

  private showComplianceCaoNotInAgreementModal(complianceCao: CaoCompliance) {
    this.modalComplianceCao = complianceCao;
    (this.$refs['compliance-cao-not-in-agreement-modal-1'] as any).show();
  }

  private toggleFavorite() {
    this.toggleFavoriteInlener({
      type: this.$route.params.type as InlenerType,
      inlener_id: Number(this.$route.params.inlener_id),
    }).then(({ favorite }) => {
      if (this.inlenerDetails) {
        this.inlenerDetails.favorite = favorite;
      }
    });
  }
}
